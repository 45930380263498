
import { defineComponent, onMounted, ref } from 'vue'

import { useSettings } from '@/composables/useSettings'
export default defineComponent({
    setup() {
        const isScroll = ref(false)
        const { isMobile } = useSettings()

        const oldTop = ref(0)
        const isTop = ref(false)

        const linesActive = ref(false)

        setTimeout(() => {
            linesActive.value = true
        }, 1000)

        // TO fix
        const scrollNav = () => {
            if (!document.scrollingElement) {
                return
            }
            const top = document.scrollingElement.scrollTop // Trigger the scroll bar, record the value
            if (oldTop.value > top) {
                isScroll.value = false
            } else {
                isScroll.value = true
            }
            oldTop.value = top // Update the old location

            if (window.scrollY < 10) {
                isTop.value = true
            } else {
                isTop.value = false
            }
        }

        const scrollToSection = (sectionId: string) => {
            const element = document.querySelector(`#${sectionId}`)
            if (element) {
                window.scrollTo({ top: element.getBoundingClientRect().top + window.scrollY - 100, behavior: 'smooth' })
            }
        }

        onMounted(() => {
            document.addEventListener('scroll', scrollNav)
        })
        return { isScroll, isMobile, scrollToSection, isTop, linesActive }
    },
})
