<template>
    <div class="o-quote" :class="{ 'o-quote--bottom': isBottom }">
        <figure class="o-quote__content" :data-aos="isMobile ? '' : 'fade-up'">
            <blockquote v-if="isMobile" class="o-quote__text" v-html="quote.textMobile"></blockquote>
            <blockquote v-else-if="isTablet" class="o-quote__text" v-html="quote.textTablet"></blockquote>
            <blockquote v-else class="o-quote__text" v-html="quote.textDesktop"></blockquote>
            <figcaption class="o-quote__author">{{ quote.author }}</figcaption>
        </figure>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useSettings } from '@/composables/useSettings'
import { Quote } from '@/interfaces/Quote'
export default defineComponent({
    props: {
        quote: {
            type: Object as PropType<Quote>,
            required: true,
        },
        isBottom: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { isMobile, isTablet } = useSettings()

        return { isMobile, isTablet }
    },
})
</script>

<style scoped></style>
