
import { useSettings } from '@/composables/useSettings'
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const date = new Date()
        const year = date.getFullYear()

        const { isMobile } = useSettings()
        return { year, isMobile }
    },
})
