import { reactive, toRefs } from 'vue'

const state = reactive({
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    activeSection: '',
})

export const useSettings = () => {
    const checkIfMobile = () => {
        if (window.innerWidth < 768) {
            state.isMobile = true
            state.isTablet = false
            state.isDesktop = false
        } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
            state.isMobile = false
            state.isTablet = true
            state.isDesktop = false
        } else if (window.innerWidth >= 1024) {
            state.isMobile = false
            state.isTablet = false
            state.isDesktop = true
        }
    }

    const changeActiveSection = (section: string) => {
        if (state.activeSection === section) {
            state.activeSection = ''
        } else {
            state.activeSection = section
        }
    }

    return {
        ...toRefs(state),
        checkIfMobile,
        changeActiveSection,
    }
}
