import { reactive, toRefs } from 'vue'
import axios from 'axios'
import { iTeam } from '@/interfaces/TeamMember'
import { IOffer } from '@/interfaces/Offer'
import { ISlide } from '@/interfaces/Carousel'
import { IServices } from '@/interfaces/Services'
import { About } from '@/interfaces/About'

const state = reactive({
    about: <About>{},
    services: <IServices>{},
    team: <iTeam>{},
    offerList: <Array<IOffer>>[],
    carousel: <Array<ISlide>>[],
})

export const useFetchData = () => {
    const fetchData = async () => {
        try {
            const response = await axios.get('https://psychoterapiajezyce.pl/api/wp-json/wp/v2/pages/6')
            state.team = response.data.acf.team
            state.offerList = response.data.acf.offer
            state.carousel = response.data.acf.slajder
            state.about = response.data.acf.about
            state.services = response.data.acf.services
            return response
        } catch (err) {
            console.log(err)
        }
    }
    // fetchData().then((res) => {
    //     team.value = res?.data.acf.team.teamMembers
    //     offerList.value = res?.data.acf.offer
    //     carousel.value = res?.data.acf.slajder
    // })
    return {
        ...toRefs(state),
        fetchData,
    }
}
