
import { defineComponent, reactive } from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { useFetchData } from '@/composables/useFetchData'

export default defineComponent({
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    setup() {
        const { carousel } = useFetchData()

        const settings = reactive({
            itemsToShow: 1,
            itemsToScroll: 1,
            autoplay: 4000,
            modelValue: 0,
            wrapAround: true,
            snapAlign: 'center',
            pauseAutoplayOnHover: false,
            breakpoints: {
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                1440: {
                    itemToShow: 1,
                    snapAlign: 'center',
                },
            },
        })

        return {
            carousel,
            settings,
        }
    },
})
