<template>
    <div id="offer" class="m-container o-offer">
        <div class="m-container__row">
            <div class="m-container__column">
                <h2 data-aos="slide-up" class="a-title a-title--section a-title--offer">Oferta</h2>
                <Accordion :tabList="offerList" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { IOffer } from '@/interfaces/Offer'
import Accordion from '@/components/Accordion.vue'

import { useFetchData } from '@/composables/useFetchData'

export default defineComponent({
    components: {
        Accordion,
    },
    setup() {
        const { offerList } = useFetchData()

        return { offerList }
    },
})
</script>

<style scoped></style>
