<template>
    <footer id="kontakt" class="o-footer m-container">
        <div class="m-container__row o-footer__row">
            <div class="m-container__column o-footer__column">
                <div class="o-footer__item o-footer__item--title">
                    <h2 class="a-title a-title--noMarginBottom a-title--section">Kontakt</h2>
                </div>
                <div class="m-container__item o-footer__item o-footer__item--address">
                    <p class="a-text">
                        <span class="a-text--bold">Jeżyckie Centrum Psychodynamiczne</span> <br />
                        ul. Poznańska 50/1, 60-851 Poznań
                    </p>
                </div>

                <div class="m-container__item o-footer__item o-footer__item--slogan">
                    <p class="a-text a-text--serif">
                        Zapraszamy do kontaktu telefonicznego<br />
                        w celu umówienia konsultacji z wybranym terapeutą.
                    </p>
                </div>

                <div class="m-container__item o-footer__item o-footer__item--map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.592961910382!2d16.907966515692838!3d52.41405287979421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470444b51b889091%3A0x8cf59bd5a7bb2873!2zUG96bmHFhHNrYSA1MC8xLCA2MC04NTAgUG96bmHFhA!5e0!3m2!1sen!2spl!4v1635286024987!5m2!1sen!2spl"
                        width="100%"
                        height="300"
                        title="Google Maps"
                        style="border: 0"
                        allowfullscreen="false"
                        loading="lazy"
                    ></iframe>
                </div>

                <ul class="m-list m-container__item o-footer__item o-footer__item--contacts">
                    <li class="a-text m-list__item m-list__item--chevron">
                        <span class="a-text--bold"> Agata Kaźmierska</span><br />
                        <a class="a-text" href="#">tel: +48 664 266 017</a>
                    </li>
                    <li class="m-list__item m-list__item--chevron">
                        <span class="a-text--bold"> Marika Nowicka</span><br />
                        <a class="a-text" href="#">tel: +48 509 118 458</a>
                    </li>
                    <li class="m-list__item m-list__item--chevron">
                        <span class="a-text--bold"> Aleksandra Adamczak</span><br />
                        <a class="a-text" href="#">tel: +48 602 618 085</a>
                    </li>
                    <li class="m-list__item m-list__item--chevron">
                        <span class="a-text--bold"> Jarosław Paluszczak</span><br />
                        <a class="a-text" href="#">tel: +48 605 235 614</a>
                    </li>
                </ul>

                <div class="m-container__item m-container__item--row o-footer__item o-footer__item--social">
                    <a
                        href="https://www.facebook.com/JezyckieCentrumPsychodynamiczne"
                        class="a-text a-text--big o-footer__socialMediaItem"
                        >Facebook</a
                    >
                </div>
                <div class="m-container__item o-footer__item o-footer__item--copyright">
                    <p class="a-text a-text--small">
                        Copyright {{ year }}<br v-if="isMobile" />
                        © Jeżyckie Centrum Psychodynamiczne
                    </p>
                </div>
                <i
                    class="
                        m-container__item
                        o-footer__item o-footer__item--icon
                        m-container__item--flexEnd
                        o-footer__icon
                    "
                    ><svg width="18" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="18" y1="1" x2="-8.74228e-08" y2="0.999998" stroke="black" stroke-width="2" />
                    </svg>
                </i>
                <div
                    class="
                        m-container__item m-container__item--row m-container__item--flexEnd
                        o-footer__item o-footer__item--madeBy
                    "
                >
                    <p class="a-text a-text--small a-text--marginRight5">
                        made by
                        <a
                            class="a-text a-text--small a-text--underline"
                            rel="noopener noreferrer"
                            target="_blank"
                            href="http://www.slyz.pl"
                            >slyz.pl</a
                        >
                    </p>
                    <p class="a-text a-text--small">
                        <a
                            class="a-text a-text--small a-text--underline"
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://digitalexp.pl"
                            >digitalexp.pl</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { useSettings } from '@/composables/useSettings'
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const date = new Date()
        const year = date.getFullYear()

        const { isMobile } = useSettings()
        return { year, isMobile }
    },
})
</script>

<style scoped></style>
