import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '@/assets/styles/main.sass'

const app = createApp(App)
app.use(router)

const aosFire = () => {
    AOS.init({
        disable: window.innerWidth < 768,
        duration: 500,
        easing: 'ease-in-out',
        delay: 50,
    })
}
aosFire()

app.mount('#app')
