
import { defineComponent, PropType, ref, reactive } from 'vue'

// import Dot from '@/components/Dot.vue'
import { iTeam, TeamMember } from '@/interfaces/TeamMember'
import { useSettings } from '@/composables/useSettings'
import { useFetchData } from '@/composables/useFetchData'
export default defineComponent({
    components: {
        // Dot,
    },
    setup() {
        interface ICoordsItem {
            id: string
            top: number | undefined
        }

        const { team } = useFetchData()

        const activeTeamMember = ref('')
        const { isMobile, isTablet, isDesktop, activeSection, changeActiveSection } = useSettings()
        const toggleActiveTeamMember = (member: TeamMember) => {
            if (activeTeamMember.value === member.name) {
                activeTeamMember.value = ''
            } else {
                if (!isDesktop.value) {
                    activeTeamMember.value = member.name
                } else {
                    const bio = document.getElementById(`${member.name}`)
                    const coords = bio?.getBoundingClientRect()
                    if (coords) {
                        window.scrollTo({ top: coords?.top + window.scrollY - 100, behavior: 'smooth' })
                    }
                }
            }
        }

        const checkTeamMemberPosition = () => {
            const teamMembers = team.value.teamMembers
            teamMembers.forEach((member: TeamMember) => {
                const bio = document.getElementById(`${member.name}`)
                const coords = bio?.getBoundingClientRect()

                if (coords) {
                    const coordsItem: ICoordsItem = reactive({
                        id: member.name,
                        top: coords.top,
                    })
                    if (coordsItem.top && coordsItem.top + window.scrollY - 200 < window.scrollY) {
                        activeTeamMember.value = coordsItem.id
                    }
                }
            })
        }

        window.addEventListener('scroll', (e) => {
            if (isDesktop.value) {
                checkTeamMemberPosition()
            }
        })
        return {
            team,
            activeTeamMember,
            toggleActiveTeamMember,
            isMobile,
            isTablet,
            isDesktop,
            activeSection,
            changeActiveSection,
        }
    },
})
