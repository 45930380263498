
import { defineComponent, ref, PropType } from 'vue'

import { useSettings } from '@/composables/useSettings'
import { ITabItem } from '@/interfaces/Accordion'

export default defineComponent({
    props: {
        tabList: {
            type: Array as PropType<Array<ITabItem>>,
        },
        title: {
            type: String,
        },
    },
    setup(props) {
        const { isMobile } = useSettings()
        const activeTabId = ref(1000)
        const toggleActiveTab = (tab: number) => {
            if (activeTabId.value === tab) {
                activeTabId.value = 1000
            } else {
                activeTabId.value = tab
            }
        }
        return { isMobile, activeTabId, toggleActiveTab }
    },
})
