<template>
    <TheNav />
    <router-view />
    <TheFooter />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import TheNav from '@/components/TheNav.vue'
import TheFooter from '@/components/TheFooter.vue'

import { useSettings } from '@/composables/useSettings'
export default defineComponent({
    components: {
        TheNav,
        TheFooter,
    },
    setup() {
        const { checkIfMobile } = useSettings()

        checkIfMobile()
        window.addEventListener('resize', () => {
            checkIfMobile()
        })

        const removeWidows = () => {
            document.querySelectorAll('p').forEach((elem) => {
                var textReplace = document.querySelector('html')
                var lettersToReplace = ['a', 'i', 'o', 'u', 'w', 'z', 'A', 'I', 'O', 'U', 'W', 'Z']
                var arrayLength = lettersToReplace.length
                console.log(elem)
                // for (var i = 0; i < arrayLength; i++) {
                //     var textSplit = textReplace?.split(' ' + lettersToReplace[i] + ' ')
                //     var textReplace = textSplit.join(' ' + lettersToReplace[i] + '&nbsp;')
                // }
            })
        }

        return {}
    },
})
</script>
