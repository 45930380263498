
import { defineComponent, ref } from 'vue'

export default defineComponent({
    props: {
        firstQuote: {
            type: Boolean,
        },
        floatingQuote: {
            type: Boolean,
        },
        team: {
            type: Boolean,
        },
    },
    setup(props) {
        const square = ref(true)
        const circle = ref(false)
        const isAnimated = ref(false)

        setInterval(() => {
            isAnimated.value = true
        }, 5000)

        setInterval(() => {
            isAnimated.value = false
        }, 15000)

        const changeShape = () => {
            setInterval(() => {
                square.value = true
                circle.value = false
            }, 5000)

            setInterval(() => {
                square.value = false
                circle.value = true
            }, 10000)
        }

        changeShape()
        return { square, circle, isAnimated }
    },
})
