<template>
    <main class="m-main o-home">
        <section class="a-section a-section--noMarginBottom">
            <Quotes :quote="quoteHero" />
            <Dot :firstQuote="true" />
        </section>
        <section class="a-section" :class="{ 'a-section--borderBottom': !isDesktop }">
            <div id="about" class="m-container o-about">
                <div class="m-container__row">
                    <div class="m-container__column o-about__sliderWrapper">
                        <Carousel />
                    </div>
                    <div class="m-container__column o-about__text m-container__column--shorter">
                        <div class="m-container__item">
                            <div class="m-container__tab">
                                <h2 class="a-title a-title--section">O centrum</h2>
                                <button
                                    v-if="isMobile"
                                    class="m-container__tabClose"
                                    :class="{ 'm-container__tabClose--active': activeSection === 'about' }"
                                    @click="changeActiveSection('about')"
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1="9" y1="4.37114e-08" x2="9" y2="18" stroke="black" stroke-width="2" />
                                        <line x1="18" y1="9" x2="-8.74228e-08" y2="9" stroke="black" stroke-width="2" />
                                    </svg>
                                </button>
                            </div>
                            <transition name="slide-fade">
                                <div
                                    v-if="!isMobile || (isMobile && activeSection === 'about')"
                                    class="o-about__content"
                                    v-html="about.text"
                                ></div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section :data-aos="isMobile ? '' : 'slide-up'" class="a-section">
            <div id="help" class="m-container">
                <div class="m-container__row">
                    <div class="m-container__column m-container__column--oCentrum">
                        <div class="m-container__tab">
                            <h2 class="a-title a-title--section">W czym możemy pomóc?</h2>
                            <button
                                v-if="isMobile"
                                class="m-container__tabClose"
                                :class="{ 'm-container__tabClose--active': activeSection === 'help' }"
                                @click="changeActiveSection('help')"
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <line x1="9" y1="4.37114e-08" x2="9" y2="18" stroke="black" stroke-width="2" />
                                    <line x1="18" y1="9" x2="-8.74228e-08" y2="9" stroke="black" stroke-width="2" />
                                </svg>
                            </button>
                        </div>
                        <transition-group name="slide-fade">
                            <template v-if="!isMobile || (isMobile && activeSection === 'help')">
                                <ul class="m-list">
                                    <div
                                        class="o-services__content"
                                        :class="{ 'a-text--width90': isMobile }"
                                        v-html="services.text"
                                    ></div>
                                    <template v-for="(service, index) in services.list" :key="index">
                                        <li
                                            class="m-list__item"
                                            :class="{ 'a-text--width90': isMobile }"
                                            v-html="service.listItem"
                                        ></li>
                                    </template>

                                    <li v-if="isMobile || isTablet" class="m-list__item">
                                        <p class="a-text a-text--serif a-text--bigger">
                                            Po więcej informacji o różnych rodzajach<br />
                                            oferowanej pomocy zapraszamy do zakładki
                                            <span
                                                @click="scrollToSection('offer')"
                                                class="a-text--serif a-text--underline a-text--pointer a-text--bigger"
                                                >oferta</span
                                            >
                                        </p>
                                    </li>
                                    <li class="m-list__item m-list__item--verticalLines">
                                        <svg
                                            width="18"
                                            height="243"
                                            viewBox="0 0 18 243"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clip-path="url(#clip0_130:304)">
                                                <path
                                                    d="M1.57361e-07 241.2L0 243L18 243L18 241.2L1.57361e-07 241.2Z"
                                                    fill="#1D1D1B"
                                                />
                                                <path
                                                    d="M1.57361e-07 237.6L0 239.4L18 239.4L18 237.6L1.57361e-07 237.6Z"
                                                    fill="#1D1D1B"
                                                />
                                                <path
                                                    d="M1.57361e-07 232.2L0 234L18 234L18 232.2L1.57361e-07 232.2Z"
                                                    fill="#1D1D1B"
                                                />
                                                <path
                                                    d="M1.57361e-07 223.2L0 225L18 225L18 223.2L1.57361e-07 223.2Z"
                                                    fill="#1D1D1B"
                                                />
                                                <path
                                                    d="M1.57361e-07 207L0 208.8L18 208.8L18 207L1.57361e-07 207Z"
                                                    fill="#1D1D1B"
                                                />
                                                <path
                                                    d="M1.57361e-07 176.4L0 178.2L18 178.2L18 176.4L1.57361e-07 176.4Z"
                                                    fill="#1D1D1B"
                                                />
                                                <path
                                                    d="M1.57361e-07 117L0 118.8L18 118.8L18 117L1.57361e-07 117Z"
                                                    fill="#1D1D1B"
                                                />
                                                <path
                                                    d="M1.57361e-07 4.57764e-05L0 1.80005L18 1.80005L18 4.735e-05L1.57361e-07 4.57764e-05Z"
                                                    fill="#1D1D1B"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_130:304">
                                                    <rect
                                                        width="18"
                                                        height="243"
                                                        fill="white"
                                                        transform="translate(18 243) rotate(-180)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </li>
                                </ul>
                            </template>
                        </transition-group>
                    </div>
                    <div v-if="!isMobile" class="m-container__column m-container__column--background">
                        <div class="m-container__floating" :data-aos="isMobile ? '' : 'slide-up'">
                            <p v-if="isDesktop" class="a-text a-text--serif a-text--big">
                                Po więcej informacji<br />
                                o różnych rodzajach oferowanej pomocy<br />
                                zapraszamy do zakładki
                                <span
                                    @click="scrollToSection('offer')"
                                    class="a-text--serif a-text--underline a-text--pointer"
                                    >oferta</span
                                >
                            </p>
                        </div>
                        <Dot :floatingQuote="true" />
                    </div>
                </div>
            </div>
        </section>
        <section class="a-section a-section--wide">
            <div class="m-container">
                <picture>
                    <source
                        srcset="@/assets/images/banners/grupowe_kwadrat.webp"
                        type="image/webp"
                        media="(max-width: 1023px)"
                    />
                    <source srcset="@/assets/images/banners/grupowe1.webp" type="image/webp" />
                    <img src="@/assets/images/banners/grupowe.jpg" alt="" class="a-image a-image--groupImage" />
                </picture>
            </div>
        </section>
        <section class="a-section">
            <Team />
            <Dot :team="true" />
        </section>
        <section class="a-section a-section--noMarginBottom">
            <Quotes :quote="quoteOutro" :isBottom="true" />
            <Dot :firstQuote="false" />
        </section>
        <section class="a-section">
            <Offer />
        </section>
    </main>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'

import Quotes from '@/components/Quotes.vue'
import Offer from '@/components/Offer.vue'
import Team from '@/components/Team.vue'
import Carousel from '@/components/Carousel.vue'
import Dot from '@/components/Dot.vue'

import { useFetchData } from '@/composables/useFetchData'
import { useSettings } from '@/composables/useSettings'

import { Quote } from '@/interfaces/Quote'

export default defineComponent({
    name: 'Home',
    components: {
        Quotes,
        Offer,
        Team,
        Carousel,
        Dot,
    },
    setup() {
        const { isMobile, isTablet, isDesktop, activeSection, changeActiveSection } = useSettings()
        const { fetchData, about, services } = useFetchData()
        const quoteHero = reactive<Quote>({
            textDesktop: `<p>"Im bardziej jest się uczciwym wobec siebie,</p>
            <p> tym większą ma się szansę na satysfakcjonujące i&nbsp;udane życie."</p>
             `,
            textTablet: `<p>"Im bardziej jest się uczciwym wobec</p>
            <p>siebie, tym większą ma się szansę</p>
            <p> na satysfakcjonujące i&nbsp;udane życie."</p>
             `,
            textMobile: `<p>"Im bardziej jest się uczciwym wobec</p>
            <p>siebie, tym większą ma się szansę</p>
            <p> na satysfakcjonujące i&nbsp;udane życie."</p>
             `,
            author: 'Nancy McWilliams',
        })
        const quoteOutro = reactive<Quote>({
            textDesktop: `<p>"Równowaga nie oznacza unikania konfliktów,</p>
            <p>równowaga to po prostu siła umożliwiająca przetrwanie</p>
            <p>przykrych emocji i radzenie sobie  z nimi."</p>`,
            textTablet: `<p>"Równowaga nie oznacza unikania konfliktów,</p>
            <p>równowaga to po prostu siła umożliwiająca</p><p>przetrwanie przykrych emocji</p><p> i radzenie sobie  z nimi."</p>`,
            textMobile: `<p>"Równowaga nie oznacza unikania</p><p> konfliktów, równowaga to po prostu siła</p><p> umożliwiająca przetrwanie przykrych</p><p> emocji i radzenie sobie  z nimi."</p>`,
            author: `M. Klein`,
        })

        fetchData()

        const scrollToSection = (sectionId: string) => {
            const element = document.querySelector(`#${sectionId}`)
            if (element) {
                window.scrollTo({ top: element.getBoundingClientRect().top + window.scrollY - 100, behavior: 'smooth' })
            }
        }

        return {
            about,
            services,
            quoteHero,
            quoteOutro,
            isMobile,
            isTablet,
            isDesktop,
            activeSection,
            changeActiveSection,
            scrollToSection,
        }
    },
})
</script>
