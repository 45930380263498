
import { defineComponent, PropType } from 'vue'
import { useSettings } from '@/composables/useSettings'
import { Quote } from '@/interfaces/Quote'
export default defineComponent({
    props: {
        quote: {
            type: Object as PropType<Quote>,
            required: true,
        },
        isBottom: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { isMobile, isTablet } = useSettings()

        return { isMobile, isTablet }
    },
})
