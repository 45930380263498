<template>
    <carousel class="m-carousel" :settings="settings">
        <slide v-for="(slide, index) in carousel" :key="index" class="m-carousel__slide">
            <img :src="slide.url" :alt="slide.alt" class="a-image a-image--carousel" />
        </slide>

        <template #addons>
            <Navigation />
            <!-- <pagination /> -->
        </template>
    </carousel>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { useFetchData } from '@/composables/useFetchData'

export default defineComponent({
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    setup() {
        const { carousel } = useFetchData()

        const settings = reactive({
            itemsToShow: 1,
            itemsToScroll: 1,
            autoplay: 4000,
            modelValue: 0,
            wrapAround: true,
            snapAlign: 'center',
            pauseAutoplayOnHover: false,
            breakpoints: {
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                1440: {
                    itemToShow: 1,
                    snapAlign: 'center',
                },
            },
        })

        return {
            carousel,
            settings,
        }
    },
})
</script>
