
import { defineComponent, PropType } from 'vue'

import { IOffer } from '@/interfaces/Offer'
import Accordion from '@/components/Accordion.vue'

import { useFetchData } from '@/composables/useFetchData'

export default defineComponent({
    components: {
        Accordion,
    },
    setup() {
        const { offerList } = useFetchData()

        return { offerList }
    },
})
