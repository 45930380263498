<template>
    <div id="team" class="m-container o-team">
        <div class="m-container__row">
            <div
                :data-aos="isMobile ? '' : 'slide-up'"
                data-aos-once="true"
                class="m-container__column o-team__column o-team__column--sticky"
            >
                <div class="m-container__tab">
                    <h2 class="a-title a-title--section">Zespół</h2>
                    <button
                        v-if="isMobile"
                        class="m-container__tabClose"
                        aria-label="Close"
                        :class="{ 'm-container__tabClose--active': activeSection === 'team' }"
                        @click="changeActiveSection('team')"
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="9" y1="4.37114e-08" x2="9" y2="18" stroke="black" stroke-width="2" />
                            <line x1="18" y1="9" x2="-8.74228e-08" y2="9" stroke="black" stroke-width="2" />
                        </svg>
                    </button>
                </div>
                <transition-group name="slide-fade">
                    <template v-if="!isMobile || (isMobile && activeSection === 'team')">
                        <p class="a-text" :class="{ 'a-text--marginRight20': isDesktop }">
                            Jesteśmy absolwentami 4-letniej, rekomendowanej przez Polskie Towarzystwo Psychologiczne
                            szkoły psychoterapii w Krakowskim Centrum Psychodynamicznym. Wszyscy ukończyliśmy także
                            psychologię na Uniwersytecie im. Adama Mickiewicza w Poznaniu, a w toku swojej pracy
                            zawodowej rozwijamy nasze kwalifikacje podczas dalszych szkoleń oraz udziału w
                            konferencjach, zarówno z zakresu psychologii, jak i psychoterapii oraz psychoseksuologii.
                            Swoją pracę terapeutyczną poddajemy regularnej superwizji u rekomendowanych superwizorów i
                            pracujemy zgodnie z kodeksem etyki Polskiego Towarzystwa Psychoterapii Psychodynamicznej.
                            Wszyscy oferujemy prowadzenie diagnozy i psychoterapii psychodynamicznej, posiadamy jednak
                            różne doświadczenia i dodatkowe kwalifikacje. Zapraszamy do bliższego zapoznania się z nami
                            poprzez poznanie doświadczenia, kwalifikacji i oferty każdego z członków naszego zespołu.
                        </p>
                    </template>
                </transition-group>
                <ul v-if="isDesktop" class="m-list o-team__names">
                    <template v-for="member in team.teamMembers" :key="member.id">
                        <li
                            class="m-list__item o-team__namesItem"
                            :class="{
                                'm-list__item--chevron': activeTeamMember === member.name,
                                'm-list__item--noIcon': activeTeamMember !== member.name,
                            }"
                            @click="toggleActiveTeamMember(member)"
                        >
                            {{ member.name }}
                        </li>
                    </template>
                </ul>
            </div>
            <div class="m-container__column o-team__column">
                <div class="m-container__item o-team__members">
                    <div v-for="member in team.teamMembers" :id="member.name" class="o-team__member" :key="member.id">
                        <div class="o-team__imageWrapper">
                            <img :src="member.image.url" :alt="member.image.alt" class="a-image" />
                        </div>
                        <div
                            class="o-team__section"
                            @click="isMobile || isTablet ? toggleActiveTeamMember(member) : null"
                        >
                            <div class="o-team__nameTab">
                                <h3 class="a-title o-team__name">{{ member.name }}</h3>
                                <button
                                    v-if="!isDesktop"
                                    aria-label="Close"
                                    class="a-button a-button__close"
                                    :class="{ 'a-button__close--active': activeTeamMember === member.name }"
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1="9" y1="4.37114e-08" x2="9" y2="18" stroke="black" stroke-width="2" />
                                        <line x1="18" y1="9" x2="-8.74228e-08" y2="9" stroke="black" stroke-width="2" />
                                    </svg>
                                </button>
                            </div>
                            <h4 class="a-text a-text--italic a-text--width80">
                                {{ member.specialization }}
                            </h4>
                        </div>
                        <div class="o-team__section" :class="{ 'o-team__section--short': !isMobile }">
                            <ul class="m-list">
                                <li class="m-list__item o-team__contactMethod">
                                    <a :href="`tel:+${member.phone}`" class="a-text o-team__contactMethod"
                                        >tel:&nbsp;<span class="a-text--bold">{{ member.phone }}</span></a
                                    ><br />
                                </li>
                                <li class="m-list__item o-team__contactMethod">
                                    <a :href="`mailto:${member.email}`" class="a-text o-team__contactMethod"
                                        >email:&nbsp;<span class="a-text--bold">{{ member.email }}</span></a
                                    >
                                </li>
                            </ul>
                        </div>

                        <transition-group name="slide-fade">
                            <template v-if="isDesktop || (!isDesktop && activeTeamMember === member.name)">
                                <div class="o-team__section" :class="{ 'o-team__section--short': !isMobile }" :key="1">
                                    <div class="o-team__content" v-html="member.description"></div>
                                </div>
                                <div class="o-team__section" :class="{ 'o-team__section--short': !isMobile }" :key="2">
                                    <h2 class="a-text a-text--bold">Kwalifikacje i wykształcenie</h2>
                                    <div
                                        class="o-team__qualifications o-team__content"
                                        v-html="member.qualifications"
                                    ></div>
                                </div>
                                <div class="o-team__section" :class="{ 'o-team__section--short': !isMobile }" :key="3">
                                    <h2 class="a-text a-text--bold">Doświadczenie zawodowe</h2>
                                    <div class="o-team__content" v-html="member.experience"></div>
                                </div>
                            </template>
                        </transition-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, reactive } from 'vue'

// import Dot from '@/components/Dot.vue'
import { iTeam, TeamMember } from '@/interfaces/TeamMember'
import { useSettings } from '@/composables/useSettings'
import { useFetchData } from '@/composables/useFetchData'
export default defineComponent({
    components: {
        // Dot,
    },
    setup() {
        interface ICoordsItem {
            id: string
            top: number | undefined
        }

        const { team } = useFetchData()

        const activeTeamMember = ref('')
        const { isMobile, isTablet, isDesktop, activeSection, changeActiveSection } = useSettings()
        const toggleActiveTeamMember = (member: TeamMember) => {
            if (activeTeamMember.value === member.name) {
                activeTeamMember.value = ''
            } else {
                if (!isDesktop.value) {
                    activeTeamMember.value = member.name
                } else {
                    const bio = document.getElementById(`${member.name}`)
                    const coords = bio?.getBoundingClientRect()
                    if (coords) {
                        window.scrollTo({ top: coords?.top + window.scrollY - 100, behavior: 'smooth' })
                    }
                }
            }
        }

        const checkTeamMemberPosition = () => {
            const teamMembers = team.value.teamMembers
            teamMembers.forEach((member: TeamMember) => {
                const bio = document.getElementById(`${member.name}`)
                const coords = bio?.getBoundingClientRect()

                if (coords) {
                    const coordsItem: ICoordsItem = reactive({
                        id: member.name,
                        top: coords.top,
                    })
                    if (coordsItem.top && coordsItem.top + window.scrollY - 200 < window.scrollY) {
                        activeTeamMember.value = coordsItem.id
                    }
                }
            })
        }

        window.addEventListener('scroll', (e) => {
            if (isDesktop.value) {
                checkTeamMemberPosition()
            }
        })
        return {
            team,
            activeTeamMember,
            toggleActiveTeamMember,
            isMobile,
            isTablet,
            isDesktop,
            activeSection,
            changeActiveSection,
        }
    },
})
</script>

<style scoped></style>
