<template>
    <div class="m-accordion">
        <div
            v-for="(item, index) in tabList"
            class="m-accordion__item"
            :key="index"
            :data-aos="isMobile ? '' : 'slide-up'"
        >
            <div class="m-accordion__tab" @click="toggleActiveTab(index)">
                <h3 class="m-accordion__title" :class="{ 'm-accordion__title--bold': activeTabId === index }">
                    {{ item.title }}
                </h3>
                <button
                    class="m-accordion__close"
                    aria-label="Close"
                    :class="{ 'm-accordion__close--active': activeTabId === index }"
                >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="9" y1="4.37114e-08" x2="9" y2="18" stroke="black" stroke-width="2" />
                        <line x1="18" y1="9" x2="-8.74228e-08" y2="9" stroke="black" stroke-width="2" />
                    </svg>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="activeTabId === index" class="m-accordion__body" v-html="item.text"></div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue'

import { useSettings } from '@/composables/useSettings'
import { ITabItem } from '@/interfaces/Accordion'

export default defineComponent({
    props: {
        tabList: {
            type: Array as PropType<Array<ITabItem>>,
        },
        title: {
            type: String,
        },
    },
    setup(props) {
        const { isMobile } = useSettings()
        const activeTabId = ref(1000)
        const toggleActiveTab = (tab: number) => {
            if (activeTabId.value === tab) {
                activeTabId.value = 1000
            } else {
                activeTabId.value = tab
            }
        }
        return { isMobile, activeTabId, toggleActiveTab }
    },
})
</script>
